import React, { useEffect, useState, useRef } from "react";
import HomeCarousel from "../../components/HomeCarousel";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const HomePage = () => {
  const [menuActive, setMenuActive] = useState("");
  const [scrolledHeader, setScrolledHeader] = useState("");

  const headerRef = useRef(null);
  const menuCloseRef = useRef(null);
  const homeSectionRef = useRef(null);
  const aboutSectionRef = useRef(null);
  const detailsSectionRef = useRef(null);
  const gallerySectionRef = useRef(null);

  const deadline = new Date("November 6, 2024").getTime();

  const handleScrollResize = () => {
    if (window.innerWidth > 992 && window.scrollY > 100) {
      setScrolledHeader("scrolled");
    } else {
      setScrolledHeader("");
    }
  };

  // Initialize special slider and hamburger menu
  useEffect(() => {
    window.addEventListener("scroll", handleScrollResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScrollResize);
    };
  }, []);

  const handleHamburgerClick = () => {
    setMenuActive("active");
  };

  const handleDocumentClick = (e) => {
    setMenuActive("");
  };

  // Countdown timer
  useEffect(() => {
    const x = setInterval(() => {
      const currentTime = new Date().getTime();
      const t = deadline - currentTime;
      const days = Math.floor(t / (1000 * 60 * 60 * 24));
      const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((t % (1000 * 60)) / 1000);

      if (t < 0) {
        clearInterval(x);
        document.getElementById("time-up").innerHTML = "TIME UP";
        document.getElementById("day").innerHTML = "0";
        document.getElementById("hour").innerHTML = "0";
        document.getElementById("minute").innerHTML = "0";
        document.getElementById("second").innerHTML = "0";
      } else {
        document.getElementById("day").innerHTML = days;
        document.getElementById("hour").innerHTML = hours;
        document.getElementById("minute").innerHTML = minutes;
        document.getElementById("second").innerHTML = seconds;
      }
    }, 1000);

    return () => clearInterval(x);
  }, [deadline]);

  return (
    <>
      <header className={`header ${scrolledHeader}`} ref={headerRef}>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="header_container d-flex flex-row align-items-center justify-content-start">
                <div className="logo_container">
                  <div className="logo">
                    <img
                      src="/images/dpa_logo.png"
                      alt=""
                      style={{ width: "185px" }}
                    />
                  </div>
                </div>

                <nav className="main_nav ml-auto">
                  <ul className="main_nav_list">
                    <li className="main_nav_item active">
                      <a href="#home" ref={homeSectionRef}>
                        Home
                      </a>
                    </li>
                    <li className="main_nav_item">
                      <a href="#about" ref={aboutSectionRef}>
                        About us
                      </a>
                    </li>
                    <li className="main_nav_item">
                      <a href="#details" ref={detailsSectionRef}>
                        Package Details
                      </a>
                    </li>
                    <li className="main_nav_item">
                      <a href="#gallery" ref={gallerySectionRef}>
                        Gallery
                      </a>
                    </li>
                    {/*<li className="main_nav_item">
                      <a href="#contact">Contact</a>
                    </li>
                     </ul><li className="main_nav_item"><a href="@Url.Action("Scores", "Home")">Contact</a></li>--> */}
                  </ul>
                </nav>

                <div
                  className="hamburger ml-auto"
                  onClick={handleHamburgerClick}
                >
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className={`menu_container menu_mm ${menuActive}`}>
        <div className="menu_close_container">
          <div
            className="menu_close"
            ref={menuCloseRef}
            onClick={handleDocumentClick}
          ></div>
        </div>

        <div className="menu_inner menu_mm">
          <div className="menu menu_mm">
            <ul className="menu_list menu_mm">
              <li className="menu_item menu_mm">
                <a href="#home" onClick={handleDocumentClick}>
                  Home
                </a>
              </li>
              <li className="menu_item menu_mm">
                <a href="#about" onClick={handleDocumentClick}>
                  About us
                </a>
              </li>
              <li className="menu_item menu_mm">
                <a href="#details" onClick={handleDocumentClick}>
                  Package Details
                </a>
              </li>
              <li className="menu_item menu_mm">
                <a href="#gallery" onClick={handleDocumentClick}>
                  Gallery
                </a>
              </li>
            </ul>
            <div className="menu_copyright menu_mm">
              Colorlib All rights reserved
            </div>
          </div>
        </div>
      </div>

      <div className="home">
        <div
          className="home_background"
          style={{ backgroundImage: `url(/images/cover.jpg)` }}
        ></div>
        <div className="home_content">
          <div className="home_content_inner">
            <div className="home_text_large">paradise</div>
            <div className="home_text_small">GOLF IN PARADISE</div>
          </div>
        </div>
      </div>

      <div className="find">
        <div
          className="find_background parallax-window"
          data-parallax="scroll"
          data-image-src="/images/find.jpg"
          data-speed="0.8"
        ></div>
        <div className="container">
          <div className="row">
            <div
              style={{ color: "#FFF", textAlign: "center", margin: "0 auto" }}
            >
              <h3>THE COUNTDOWN IS ON</h3>
              <div className="timer">
                <div>
                  <span className="days" id="day"></span>
                  <div className="smalltext">Days</div>
                </div>
                <div>
                  <span className="hours" id="hour"></span>
                  <div className="smalltext">Hours</div>
                </div>
                <div>
                  <span className="minutes" id="minute"></span>
                  <div className="smalltext">Minutes</div>
                </div>
                <div>
                  <span className="seconds" id="second"></span>
                  <div className="smalltext">Seconds</div>
                </div>
                <p id="time-up"></p>
              </div>
              <h3>Join us on our journey to paradise!</h3>
              <h3>November 6th-11th</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="top" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_title text-center mb-3">
                <h2>About Us</h2>
              </div>
            </div>

            <div className="col-lg-12">
              <p>
                The Dominican Pro Am is a gathering of friends, old & new that
                travel to the Caribbean for fun and comradery. During the trip,
                you will experience golf on the finest courses the Caribbean has
                to offer. Golfers of all skill levels and experience converge
                from across the United States to test their skills and marvel at
                the majestic beauty of a stunning collection of challenging, yet
                accommodating golf courses. Whether you're a golfer or not, the
                all-inclusive resort offers a delightful experience with
                amenities that everyone can enjoy!
              </p>
            </div>

            <div
              className="col-lg-12"
              style={{
                margin: "0 auto",
                paddingTop: "40px",
                textAlign: "center",
              }}
            >
              <h3>
                Dominican Pro Am is Sponsored by Nathaniel Lee,{" "}
                <span style={{ color: "#AAA" }}>
                  Lee Cossell & Feagley, LLP
                </span>
              </h3>
              <h5>
                Found out more about our sponsor by visiting{" "}
                <a href="https://www.nleelaw.com" target="_blank">
                  www.NLeeLaw.com
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="container" id="details">
        <h2>Resort Details</h2>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Feature</th>
              <th scope="col">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Number of Buildings</td>
              <td>4</td>
            </tr>
            <tr>
              <td>Key Location</td>
              <td>Building #2; Iberostar Rose Hall Suites</td>
            </tr>
            <tr>
              <td>Accommodation Options</td>
              <td>
                Rooms in both Iberostar Grand (adults only) and Iberostar
                Selection Suites
              </td>
            </tr>
            <tr>
              <td>Activities and Amenities</td>
              <td>
                Golf, Round trip transportation, Meals and beverages, Internet,
                Cocktail reception, Awards dinner, 20% Spa discount
              </td>
            </tr>
            <tr>
              <td>Package Options</td>
              <td>
                Various packages for golfers and non-golfers, single and double
                occupancy
              </td>
            </tr>
          </tbody>
        </table>

        <h2>Package Prices</h2>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Package Type</th>
              <th scope="col">Description</th>
              <th scope="col">Price (per person)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="4">Iberostar Selection Rose Hall Suites</td>
              <td>Single Occupancy Golfer</td>
              <td>$3,008.00</td>
            </tr>
            <tr>
              <td>Double Occupancy Golfer</td>
              <td>$2,046.00</td>
            </tr>
            <tr>
              <td>Single Occupancy Non-Golfer</td>
              <td>$2,442.00</td>
            </tr>
            <tr>
              <td>Double Occupancy Non-Golfer</td>
              <td>$1,480.00</td>
            </tr>
            <tr>
              <td rowSpan="4">Iberostar Rose Hall Grand</td>
              <td>Single Occupancy Golfer</td>
              <td>$4,237.00</td>
            </tr>
            <tr>
              <td>Double Occupancy Golfer</td>
              <td>$2,703.00</td>
            </tr>
            <tr>
              <td>Single Occupancy Non-Golfer</td>
              <td>$3,671.00</td>
            </tr>
            <tr>
              <td>Double Occupancy Non-Golfer</td>
              <td>$2,137.00</td>
            </tr>
          </tbody>
        </table>

        <h2>Payment Details</h2>
        <p>
          Payments can be made through the provided{" "}
          <a href="https://form.jotform.com/240953371850155">link</a>. Initial
          payment is due by June 1st, with subsequent payments on August 1st,
          and the final payment by September 1st.
        </p>

        <h2>Junior Golfer Costs</h2>
        <p>Golf and room costs: $953.00</p>
        <p>Non-Golfing Juniors: $743.00</p>
      </div>

      <div className="special" id="gallery" style={{ paddingBottom: "0" }}>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section_title text-center">
                <h2>Gallery</h2>
                <div>take a look at some photos from previous years</div>
              </div>
            </div>
          </div>
        </div>
        <div className="special_content">
          <div>
            <HomeCarousel />
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 footer_col">
              <div className="footer_about">
                <div className="logo_container">
                  <div className="logo">
                    <img
                      src="/images/dpa_logo.png"
                      alt=""
                      style={{ width: "180px" }}
                    />
                  </div>
                </div>
                <div className="copyright">
                  Copyright &copy;
                  <script>document.write(new Date().getFullYear());</script> All
                  rights reserved | This template is made with{" "}
                  <i className="fa fa-heart-o" aria-hidden="true"></i> by{" "}
                  <a href="https://colorlib.com" target="_blank">
                    Colorlib
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 footer_col"></div>

            <div className="col-lg-4 footer_col">
              <div className="tags footer_tags">
                <div className="footer_title">Tags</div>
                <ul className="tags_content d-flex flex-row flex-wrap align-items-start justify-content-start">
                  <li className="tag">
                    <a href="#">travel</a>
                  </li>
                  <li className="tag">
                    <a href="#">beach</a>
                  </li>
                  <li className="tag">
                    <a href="#">offer</a>
                  </li>
                  <li className="tag">
                    <a href="#">vacation</a>
                  </li>
                  <li className="tag">
                    <a href="#">trip</a>
                  </li>
                  <li className="tag">
                    <a href="#">city break</a>
                  </li>
                  <li className="tag">
                    <a href="#">adventure</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div id="loadingOverlay" className="overlay">
        <div className="spinner"></div>
        <br />
        <span style={{ fontSize: "36px", fontWeight: "600" }}>
          Sending Message...
        </span>
      </div>
    </>
  );
};

export default HomePage;
